.reveal-partners{
  transform: translateX(150px);
  opacity: 0;
  transition: 2s all ease;
}

.reveal-partners.active{
  transform: translateY(0);
  opacity: 1;
}

.reveal-skills{
  transform: translateX(-150px);
  opacity: 0;
  transition: 2s all ease;
}

.reveal-skills.active{
  transform: translateY(0);
  opacity: 1;
}