.container {
  padding: 70px 0;
  text-align: center;
}

.container-partners {
  padding: 70px 0;
  text-align: center;
  display: flex;
}

.grid-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 1rem 0;
}

@media (max-width: 768px)
{
  .grid-container
   {
    flex-direction: column;
   }
}

.font {
  color: #999;
  overflow: hidden;
  margin-top: 0px;
  font-size: 16px;
  text-align: left;
  font-weight: 400;
}

.parallax {
  width: 450px;
  height: 450px;
}

@media (max-width: 768px)
{
  .mobile-skills
   {
    padding-top: 3rem;
    margin-top: 3rem;
   }
}